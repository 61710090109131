import Vue from 'vue';
import colors from 'vuetify/lib/util/colors'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg'
	},
  theme: {
    themes: {
      light: {
        info: '#00C4AA',
        rose: '#E01E5A',
        silver: '#f2f2f2',
        error: colors.red.darken4,
        accent: colors.blue.lighten4,
        success: colors.green.accent4,
        warning: colors.lime.accent2,
        primary: colors.blue.darken4,
        secondary: colors.blueGrey.lighten5,
      },
    },
  },
});
