<template>
  <div>
		<div class="navbar-bg-image pb-6 px-4">
			<v-container>
				<v-row class="pt-2 pt-md-6">
					<v-col cols="auto" class="d-flex flex-column align-center white--text">
						<img :src="constants.LOGO_NEGATIVE_URL" width="140" height="24" class="d-sm-none" />
						<img :src="constants.LOGO_NEGATIVE_URL" width="256" height="44" class="d-none d-sm-flex" />
						<span class="d-none d-md-block font-weight-light letter-spacing-6">HUMAN RESOURCES</span>
					</v-col>
					<v-col class="text-right">
						<a href="portal" class="white--text mr-3">Portal</a>
					</v-col>
				</v-row>
        <div class="white--text text-center">
          <div class="font-weight-light pb-5 pt-10 font-size-28 font-size-sm-48 font-size-lg-64">Get HR done with Taskly</div>
					<h1 class="font-size-18 font-size-md-25 font-weight-regular">
						HR expertise on demand,</br>
						ready to handle tasks big and small.
					</h1>
					<div class="mb-16">
						<v-btn
							color="info"
							elevation="10"
							class="font-size-20 font-size-sm-32 font-weight-light my-12 pa-8 pa-sm-12"
							@click="getStarted">
							Get Started
						</v-btn>
          </div>
        </div>
			</v-container>
		</div>

    <main class="position-relative pb-8">

			<v-container class="div-style-top pa-6 pa-sm-14">
				<v-row justify="center">
					<v-col xl="8">
						<h2 class="font-size-14 font-weight-medium opacity-70 text-uppercase">A Few of the Tasks We Tackle</h2>
					</v-col>
				</v-row>
				<v-row justify="center" class="font-size-14 mt-1">
					<v-col cols="12" md="6" xl="4" class="mb-4">
						<span class="primary--text text-h3">What can we take off your to-do list today?</span>
					</v-col>
					<v-col cols="12" md="6" xl="4">
						<v-row dense class="mb-8" v-for="service in services" :key='service.title'>
							<v-col cols="auto" class="mr-2">
								<v-avatar color="info" size="40"><v-icon dark size="25">{{ icons[service.icon] }}</v-icon></v-avatar>
							</v-col>
							<v-col class="d-flex flex-column">
								<h3 class="font-weight-medium font-size-14 font-size-xl-20">{{ service.title }}</h3>
								<span class="opacity-70 font-size-xl-16">{{ service.description }}</span>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>

			<v-container class="pa-6 pa-sm-14">
				<v-row justify="center" class="mb-12">
					<v-col lg="10" xl="9">
						<h2 class="font-size-14 font-weight-medium opacity-70 text-uppercase">Getting Started Is Easy</h2>
						<div class="font-size-50 text-h3 primary--text mt-2">How Taskly works</div>
					</v-col>
				</v-row>
				<v-row justify="center" class="mb-12">
					<v-col sm="6" xl="5">
						<v-img src="https://taskly.azureedge.net/public/images/future-1.webp" alt="" class="bg-size-contain" />
					</v-col>
					<v-col sm="6" lg="4" class="pl-sm-10">
						<h3 class="font-size-20 font-size-md-24 mb-4 font-weight-regular">1. Submit your task</h3>
						<div class="opacity-70 font-size-14 font-size-md-18 pb-6">
							Tell us what you need done. A Taskly Task Manager will clarify the task to ensure we deliver exactly what you're looking for.
						</div>
						<div class="opacity-70 font-size-14 font-size-md-18">
							Don't worry&mdash;you don't pay until an expert starts working on your Task.
						</div>
					</v-col>
				</v-row>
				<v-row justify="center" class="mb-12">
					<v-col sm="6" lg="4" class="pr-sm-10" order="1" order-sm="0">
 						<h3 class="font-size-20 font-size-md-24 mb-4 font-weight-regular">2. We leap into action</h3>
            <p class="opacity-70 font-size-14 font-size-md-18">One of our SHRM-certified HR experts will get started on your task right away.</p>
					</v-col>
					<v-col sm="6" xl="5">
 						<v-img src="https://taskly.azureedge.net/public/images/future-2.webp" alt="" class="bg-size-contain ml-neg-50px" />
					</v-col>
				</v-row>
				<v-row justify="center">
					<v-col sm="6" xl="5">
      			<v-img src="https://taskly.azureedge.net/public/images/future-3.webp" alt="" class="bg-size-contain" />
					</v-col>
					<v-col sm="6" lg="4" class="pl-sm-10">
            <h3 class="font-size-20 font-size-md-24 mb-4 font-weight-regular">3. Rest easy</h3>
            <p class="opacity-70 font-size-14 font-size-md-18">
							Sit back and relax. Monitor task progress, or start another task, whenever you want. We'll let you know when we're done!
						</p>
					</v-col>
				</v-row>
			</v-container>
    </main>

    <footer class="d-flex flex-column">

      <v-row class="div-style-bottom primary py-2 py-md-8 mx-2 mx-sm-3 mx-md-12">
				<v-col class="text-center">
            <div class="white--text font-size-45 font-weight-light mt-8 my-md-8">
              Let's get started!
						</div>
						<v-btn
							color="info"
							elevation="10"
							class="font-size-24 font-size-sm-32 font-weight-light my-12 pa-8 pa-sm-12"
							@click="getStarted">
							Get Started
						</v-btn>
				</v-col>        ​
      </v-row>

			<div class="primary darken-4 pt-100px pb-12">
				<v-container class="px-8">
					<v-row align="center" class="flex-column flex-sm-row">
						<v-col cols="auto">
							<v-img :src="constants.LOGO_NEGATIVE_URL" width="120" height="21" class="opacity-70" />
						</v-col>
						<v-col class="white--text opacity-70 text-center">
							<a href="https://blog.taskly.com" class="white--text">Blog</a>
							•
							<router-link to="/privacyPolicy" class="white--text text-no-wrap">Privacy Policy</router-link>
							•
							<router-link to="/termsOfUse" class="white--text text-no-wrap">Terms of Use</router-link>
						</v-col>
						<v-col cols="auto" class="text-right">
							<span class="white--text font-size-14 opacity-70">©{{ new Date().getFullYear() }} Taskly Inc.</span>
						</v-col>
					</v-row>
				</v-container>
			</div>

    </footer>
  </div>
</template>

<script>
	import constants from '@/constants'
	import icons from '@/icons'

  export default {
    data() {
      return {
				serviceId: null,
				services: [
					{
						icon: 'mdiBookOpenPageVariant',
						title: 'Employee Handbook Review',
						description: 'Is your handbook full of legal pitfalls? We\'ll find and fix them.'
					},
					{
						icon: 'mdiAccountCheck',
						title: 'I-9 Audit',
						description: 'Can you afford for your I-9s to be out of compliance?  Let us get you there quickly and painlessly.'
					},
					{
						icon: 'mdiDatabase',
						title: 'HR Information System Recommendations',
						description: 'Still keeping paper documentation? We\'ll help you find the best fit for your needs.'
					},
					{
						icon: 'mdiFormatListChecks',
						title: 'Wage Survey',
						description: 'Struggling to find and retain good talent? We\'ll make sure you\'re in line with market expectations. '
					},
					{
						icon: 'mdiFileDocument',
						title: 'Job description review and posting',
						description: 'Are your open positions going unfilled? We\'ll make your job descriptions more compelling.'
					},
					{
						icon: 'mdiFileChart',
						title: 'Much, much more!',
						description: 'Our SHRM-certified specialists can answer HR questions and perform general tasks that free up your time.'
					}
				],
				...icons,
				...constants
      }
    },
		methods: {
			login() {
				this.authenticateOidc('portal')
			},
			logout() {
				this.$store.dispatch('logout')
			},
			getStarted() {
				gtag('event', 'action', {
					'event_category': 'call_to_action',
					'event_label': 'main_button'
				})
				window.location = "https://share.hsforms.com/1DLNE50K-T4qzMy4B-KzOWw57c3v"
			}
		}
  }
</script>

<style scoped lang="scss">

  .navbar-bg-image {
    background-image: url("https://taskly.azureedge.net/public/images/navbar-image.webp") !important;
    background-size: cover;
	}

  .div-style-top {
		margin-top: -80px;
    box-shadow: 0 43px 52px rgba(0, 54, 79, 0.1);
    background-color: white;
    top: 75vh;
    left: 0;
    right: 0;
    z-index: 100;
	}

	.div-style-bottom {
    margin-bottom: -60px;
    z-index: 24;
  }
</style>
