<template>
  <v-app>
    <v-main tag="div">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    components: {},
    data() {
      return {}
    }
  };
</script>

<style lang="scss">
  @import "scss/fontSizes.scss";
	@import "scss/utilities.scss";
	@import "scss/overrides.scss";
</style>
