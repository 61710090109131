import {
	mdiBookOpenPageVariant,
	mdiAccountCheck,
	mdiDatabase,
	mdiFormatListChecks,
	mdiFileDocument,
	mdiFileChart,
	mdiClose,
	mdiPause,
	mdiCheck,
	mdiMagnify,
	mdiArrowLeft,
	mdiFileOutline,
	mdiChevronLeft,
	mdiDotsVertical,
	mdiPhone,
	mdiFile,
	mdiPaperclip,
	mdiSend,
	mdiCalendarCheck,
	mdiHumanGreetingProximity,
	mdiCashMultiple
} from '@mdi/js'

export default Object.freeze({
	icons: {
		mdiBookOpenPageVariant: mdiBookOpenPageVariant,
		mdiAccountCheck: mdiAccountCheck,
		mdiDatabase: mdiDatabase,
		mdiFormatListChecks: mdiFormatListChecks,
		mdiFileDocument: mdiFileDocument,
		mdiFileChart: mdiFileChart,
		mdiClose: mdiClose,
		mdiPause: mdiPause,
		mdiCheck: mdiCheck,
		mdiMagnify: mdiMagnify,
		mdiArrowLeft: mdiArrowLeft,
		mdiFileOutline: mdiFileOutline,
		mdiChevronLeft: mdiChevronLeft,
		mdiDotsVertical: mdiDotsVertical,
		mdiPhone: mdiPhone,
		mdiFile: mdiFile,
		mdiPaperclip: mdiPaperclip,
		mdiSend: mdiSend,
		mdiCalendarCheck: mdiCalendarCheck,
		mdiHumanGreetingProximity: mdiHumanGreetingProximity,
		mdiCashMultiple: mdiCashMultiple
	}
})
