import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/Home'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: home
		},

		{
			path: '/privacyPolicy',
			name: 'privacyPolicy',
			component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/views/PrivacyPolicy')
		},

		{
			path: '/termsOfUse',
			name: 'termsOfUse',
			component: () => import(/* webpackChunkName: "termsOfUse" */ '@/views/TermsOfUse')
		},
	]
})

export default router